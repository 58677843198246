import { render, staticRenderFns } from "./OverlayControllerParts.vue?vue&type=template&id=53de3e49&scoped=true"
import script from "./OverlayControllerParts.vue?vue&type=script&lang=ts"
export * from "./OverlayControllerParts.vue?vue&type=script&lang=ts"
import style0 from "./OverlayControllerParts.vue?vue&type=style&index=0&id=53de3e49&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53de3e49",
  null
  
)

export default component.exports