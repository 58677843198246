import { render, staticRenderFns } from "./StandingsDetailsPage.vue?vue&type=template&id=ed0ad068&scoped=true"
import script from "./StandingsDetailsPage.vue?vue&type=script&lang=ts"
export * from "./StandingsDetailsPage.vue?vue&type=script&lang=ts"
import style0 from "./StandingsDetailsPage.vue?vue&type=style&index=0&id=ed0ad068&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed0ad068",
  null
  
)

export default component.exports