import { render, staticRenderFns } from "./LinkPage.vue?vue&type=template&id=428bbaad&scoped=true"
import script from "./LinkPage.vue?vue&type=script&lang=ts"
export * from "./LinkPage.vue?vue&type=script&lang=ts"
import style0 from "./LinkPage.vue?vue&type=style&index=0&id=428bbaad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428bbaad",
  null
  
)

export default component.exports