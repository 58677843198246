import { render, staticRenderFns } from "./PaymentConfirmPage.vue?vue&type=template&id=243bb166&scoped=true"
import script from "./PaymentConfirmPage.vue?vue&type=script&lang=ts"
export * from "./PaymentConfirmPage.vue?vue&type=script&lang=ts"
import style0 from "./PaymentConfirmPage.vue?vue&type=style&index=0&id=243bb166&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243bb166",
  null
  
)

export default component.exports