import { render, staticRenderFns } from "./VideoInformationSection.vue?vue&type=template&id=32f6644e&scoped=true"
import script from "./VideoInformationSection.vue?vue&type=script&lang=ts"
export * from "./VideoInformationSection.vue?vue&type=script&lang=ts"
import style0 from "./VideoInformationSection.vue?vue&type=style&index=0&id=32f6644e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f6644e",
  null
  
)

export default component.exports