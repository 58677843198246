import { render, staticRenderFns } from "./MypageTicketListSection.vue?vue&type=template&id=22243784&scoped=true"
import script from "./MypageTicketListSection.vue?vue&type=script&lang=ts"
export * from "./MypageTicketListSection.vue?vue&type=script&lang=ts"
import style0 from "./MypageTicketListSection.vue?vue&type=style&index=0&id=22243784&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22243784",
  null
  
)

export default component.exports