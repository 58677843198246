import { render, staticRenderFns } from "./HighlightCardParts.vue?vue&type=template&id=0fb6ec42&scoped=true"
import script from "./HighlightCardParts.vue?vue&type=script&lang=ts"
export * from "./HighlightCardParts.vue?vue&type=script&lang=ts"
import style0 from "./HighlightCardParts.vue?vue&type=style&index=0&id=0fb6ec42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb6ec42",
  null
  
)

export default component.exports