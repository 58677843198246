import { render, staticRenderFns } from "./AutopoliceParts.vue?vue&type=template&id=3521607e&scoped=true"
import script from "./AutopoliceParts.vue?vue&type=script&lang=ts"
export * from "./AutopoliceParts.vue?vue&type=script&lang=ts"
import style0 from "./AutopoliceParts.vue?vue&type=style&index=0&id=3521607e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3521607e",
  null
  
)

export default component.exports